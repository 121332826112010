/* site.scss */

@import "audio_player";

h3.hashtags {
    margin: 6rem 0;
    text-transform: uppercase;
    color: var(--color-text-medium);
    word-spacing: 1.75rem;
    font-weight: 100;
    font-family: 'RobotoMono-Light', 'Roboto-Light';
    text-align: center;
}

a.title {
    display: block;
    font-size: 1.25rem;
}

a.special {
    text-transform: uppercase;

    &:hover {
        color: var(--color-text-medium);
        text-decoration: none;
    }
}

.full-height.home {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: var(--color-page-background);
    padding: 0 0;
    margin-bottom: 0;
    text-align: center;

    div.green-logistics {

        img {
            height: 400px;
            width: 100%;
            display: inline-block;
            margin: 0;
        }
    }

    a {
        font-size: .75rem;
        line-height: 4rem;
        font-family: 'RobotoMono-Bold', 'Roboto-Bold';
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0;

        span {
            margin: 0 .35rem;
            padding: .35rem .7rem;
            color: var(--color-background);
            background-color: var(--color-background-inverse);
        }

        span:hover {
            background-color: var(--color-medium);
        }
    }
}


div.counter {
    background-image: url('/artwork/background-system-cross.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    padding: 1.5rem 0 2.5rem 0;
    text-align: center;
    margin: 5rem 0 5rem 0;

    h1 {
        text-align: center;
        font-size: 3rem;
        font-family: 'RobotoMono-Regular', 'Roboto' !important;
        color: var(--color-text);
        line-height: 1;
        margin: 0px;
        padding: 0px;
    }

    div.tally {
        color: var(--color-text);
        display: block;
        height: 100%;
        text-align: center;
        margin: 0 auto;
        padding: 0;

        h2 {
            text-align: center;
            font-size: 2rem;
            font-family: 'RobotoMono-Regular', 'Roboto' !important;
            color: var(--color-text);
            line-height: 1;
            margin: 0px;
            padding: 0px;
        }

        h3 {
            font-size: 11em;
            font-family: 'RobotoMono-Bold', 'Roboto-Bold';
            color: var(--color-text);
            line-height: 1;
            text-align: center;
            margin: 3rem auto;
            padding: 0px;
        }

        p {
            color: var(--color-text);
            display: inline-block;
            font-size: 2rem;
            font-family: 'RobotoMono-Regular', 'Roboto';
            font-style: normal;
            text-transform: uppercase;
            letter-spacing: 10px;
            text-align: center;
        }

        small {
            display: block;
            font-family: 'RobotoMono-Regular', 'Roboto';
            font-style: normal;
            text-transform: uppercase;
            letter-spacing: 9px;
            line-height: 2rem;
        }
    }
}


main.updates {
    ul {
        time {
            display: block;
            color: var(--color-text-medium);
            padding: .5rem 0;
            font-family: 'RobotoMono-Regular';
        }
    }
}


div.list-group {
    a {            
        img[src$=".svg"] {
            display: inline-block;
            height: 30px;
            filter: var(--svg-filter);
        }
    }
}

article {

    .meta {
        border-top: 1px solid var(--color-light);
        border-bottom: 1px solid var(--color-light);
        padding: .4rem 0;
        color: var(--color-text-medium);
        font-family: 'RobotoMono-Regular';
        font-size: var(--font-size-details);

        img {
            height: 18px;
            filter: invert(42%) brightness(100%);
        }

    }

    main {
        padding: 0;

        img[src$=".jpg"],
        img[src$=".jpeg"],
        img[src$=".png"] {
            display:block;
            max-height: 550px;
            max-width: 95%;
            margin: 3rem auto 3rem 0;
            padding: 8px;
            border: 1px solid var(--color-text-dark);
            box-shadow: 8px 8px var(--color-text-dark);
        }

        img[src$=".svg"] {
            display:block;
            width: 100%;
            max-height: 550px;
            margin: 3.5rem auto 3.5rem 0;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0 0 2rem 0;

            li {
                margin: .75rem 0;
            }

            li::before {
                content: "\25A0";
                color: var(--color-text-dark);
                display: inline-block;
                width: 1em;
                margin-left: 0;
                margin-right: 1rem;
            }
        }

        ol {
            list-style: none;
            counter-reset: li;
            padding: 0;
            margin: 0 0 2rem 0;
        }

        ol li::before {
            content: '0' counter(li);
            color: var(--color-text-medium);
            font-family: 'RobotoMono-Bold';
            display: inline-block;
            width: 2em;
            margin-left: 0em
        }

        ol li {
            counter-increment: li
        }
    }
}


div.page-list {

    header {
        margin-bottom: 0;
        background: var(--color-text);
        padding: 0.5rem 2rem;

        h1 {
            color: var(--color-page-background);
            padding: 0;
            margin: 0;
        }
    }

    main {
        padding: 1rem 2rem .5rem 2rem;
        border: 1px solid var(--color-text);

        h3 {
            padding-bottom: 1rem;
            border-bottom: 1px dotted var(--color-text-medium);

            a {
                color: var(--color-text);
                text-transform: uppercase;

                &:hover {
                    color: var(--color-text-light);
                }
            }
        }
    }
}

table.project-meta {
    img[src$=".svg"] {
        display: inline-block;
        height: 21px;
    }
}
